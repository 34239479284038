var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$acl.check('galleryUpload'))?_c('vs-button',{staticClass:"new-folder w-full mb-3 md:my-3 cursor-pointer",attrs:{"color":"primary","type":"relief","icon-pack":"feather","icon":"icon-folder","disabled":_vm.disableNewFolder},on:{"click":function($event){$event.preventDefault();return _vm.onNewFolder($event)}}},[_c('div',{staticClass:"icon-add-folder"},[_vm._v(_vm._s(_vm.$t("gallery.folder.new.title")))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('vs-prompt',{attrs:{"title":_vm.$t('gallery.folder.new.title'),"accept-text":_vm.$t('gallery.folder.form.submit'),"active":_vm.activePromptFolder,"is-valid":!invalid},on:{"update:active":function($event){_vm.activePromptFolder=$event},"accept":_vm.acceptCreateFolder}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":_vm.$t('gallery.folder.form.input.name'),"rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"mt-5 w-full",attrs:{"type":"text","label":_vm.$t('gallery.folder.form.input.name'),"placeholder":_vm.$t('gallery.folder.form.input.name.placeholder')},model:{value:(_vm.folder.name),callback:function ($$v) {_vm.$set(_vm.folder, "name", $$v)},expression:"folder.name"}}),(errors.length > 0)?_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":_vm.$t('gallery.folder.form.input.description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"mt-5 w-full",attrs:{"label":_vm.$t('gallery.folder.form.input.description'),"placeholder":_vm.$t('gallery.folder.form.input.description.placeholder')},model:{value:(_vm.folder.description),callback:function ($$v) {_vm.$set(_vm.folder, "description", $$v)},expression:"folder.description"}}),(errors.length > 0)?_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"w-full block mt-5",attrs:{"name":_vm.$t('gallery.folder.form.input.date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"label-form"},[_vm._v(_vm._s(_vm.$t("gallery.folder.form.input.date")))]),_c('datepicker',{staticClass:"w-full",attrs:{"placeholder":_vm.$t('gallery.folder.form.input.date.placeholder'),"language":_vm.langFr,"fullMonthName":true,"monday-first":true,"minimumView":"day","initialView":"day","format":"dd MMMM yyyy","name":"date"},model:{value:(_vm.folder.date),callback:function ($$v) {_vm.$set(_vm.folder, "date", $$v)},expression:"folder.date"}}),(errors.length > 0)?_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"w-full block mt-5"},[_c('small',{staticClass:"label-form"},[_vm._v(" "+_vm._s(_vm.$t("gallery.upload.form.input.tags"))+" ")]),_c('multiselect',{ref:"multiselect",staticClass:"multiselect",attrs:{"multiple":true,"options":_vm.tags,"showLabels":false,"taggable":true,"trackBy":"@id","label":"name","placeholder":_vm.$t('gallery.upload.form.multiselect.tags.choose'),"tag-placeholder":_vm.$t('gallery.upload.form.multiselect.tags.add'),"close-on-select":false,"limit":5},on:{"tag":_vm.addTag},model:{value:(_vm.folder.tags),callback:function ($$v) {_vm.$set(_vm.folder, "tags", $$v)},expression:"folder.tags"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(_vm.tagError))])],1)],1)]}}],null,false,3499806092)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }