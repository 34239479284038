import moduleFolder from './moduleFolder'

export default {
  created () {
    if (!moduleFolder.isRegistered) {
      this.$store.registerModule('folder', moduleFolder)
      moduleFolder.isRegistered = true
    }
  }
}
