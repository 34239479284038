import moduleMedia from './moduleMedia'

export default {
  created () {
    if (!moduleMedia.isRegistered) {
      this.$store.registerModule('media', moduleMedia)
      moduleMedia.isRegistered = true
    }
  }
}
