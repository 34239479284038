<template>
  <span @click="isSelectedAllMedias">
    <vs-chip
      :color="
        isAllMediasSelected && selectedMedias.length > 0 ? 'primary' : ''
      "
      class="w-max ml-2 cursor-pointer"
    >
      <vs-avatar icon="icon-check" icon-pack="feather" color="primary" />
      <p class="text-xs">{{ $t('gallery.folder.button.medias.select.all') }}</p>
    </vs-chip>
  </span>
</template>

<script>
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
const cloneDeep = require('lodash.clonedeep')
export default {
  mixins: [moduleFolderMixin, moduleMediaMixin],
  props: {
    medias: {
      type: Array,
      required: true
    }
  },
  computed: {
    selectedMedias () {
      return this.$store.state.media.selectedMedias
    },
    isAllMediasSelected () {
      return this.$store.state.media.isAllMediasSelected
    }
  },
  methods: {
    isSelectedAllMedias () {
      this.$store.commit('media/SET_ALL_MEDIAS_SELECTED', !this.isAllMediasSelected)
      if (this.isAllMediasSelected) {
        this.$store.commit('media/ADD_ALL_MEDIAS', cloneDeep(this.medias))
      } else {
        this.$store.commit('media/CLEAR_SELECTED_MEDIAS')
      }
    }
  }
}
</script>

<style></style>
