<template>
  <vs-sidebar
    :click-not-close="true"
    position-right
    default-index="1"
    color="primary"
    class="edit-element sidebarx"
    v-model="sidebarActive"
  >
    <div class="flex flex-col px-3">
      <sidebar-folder-form @uncheckFolders="$emit('uncheckFolders')" @cancelSidebar="$emit('cancelSidebar')" />
      <sidebar-media-form
        @refreshGallery="$emit('refreshGallery')"
        @uncheckMedias="$emit('uncheckMedias')"
        @cancelSidebar="$emit('cancelSidebar')"
      />
    </div>
  </vs-sidebar>
</template>

<script>
import moduleTagsMixin from '@/store/tag/moduleTagMixin'
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
import SidebarFolderForm from '../gallery-form/SidebarFolderForm'
import SidebarMediaForm from '../gallery-form/SidebarMediaForm'
export default {
  mixins: [moduleTagsMixin, moduleFolderMixin, moduleMediaMixin],
  components: {
    SidebarFolderForm,
    SidebarMediaForm
  },
  computed: {
    sidebarActive () {
      return (
        this.$store.state.folder.totalSelectedFolders > 0 ||
        this.$store.state.media.totalSelectedMedias > 0
      )
    }
  },
  data () {
    return {}
  }
}
</script>
<style lang="scss">
.vs-input--input:disabled, .vs-input--input:disabled+.vs-input--placeholder {
    opacity: 1;
    color: rgb(102, 102, 102) !important;
}
.vs-sidebar {
  z-index: 1;
}
.vs-textarea {
  font-size: 1rem;
  font-weight: 400;
  font-family: sans-serif;
  &::placeholder {
    color: rgb(102, 102, 102) !important;
  }
  &:disabled {
    background: #fafafa !important;
    color: rgb(102, 102, 102) !important;
  }
}
.vs-sidebar--items {
  padding: 7px 0;
}
.vs-con-textarea {
  margin-bottom: 0px;
}
.multiselect-custom {
  margin: 0 auto;
}
span.multiselect__placeholder,
input.multiselect__input::placeholder {
  color: #b3b3b3;
  font-size: 0.9rem;
}
.con-vs-chip {
  min-height: 19px;
}
.vs-sidebar--background {
  display: none;
}
.label-form {
  font-size: 0.85rem;
  padding-left: 5px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .vs-sidebar {
    width: 220px !important;
  }
}
</style>
