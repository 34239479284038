<template>
  <vs-tooltip v-if="$acl.check('galleryExport')" :text="$t('gallery.folder.filter.tooltip.share')">
    <vs-button
      @click.prevent="onShare"
      :disabled="!sidebarActive"
      size="small"
      icon="share"
    >
      <ValidationObserver v-slot="{ invalid }">
        <vs-prompt
          :title="$t('gallery.folder.filter.dialog.share.title')"
          :accept-text="$t('gallery.folder.form.submit')"
          :active.sync="activePromptShare"
          :is-valid="!invalid"
          @accept="acceptShare"
        >
          <ValidationProvider
            :name="$t('gallery.folder.filter.dialog.share.expiration')"
            class="w-full block mb-5"
            rules="required"
            v-slot="{ errors }"
          >
            <small class="label-form">{{
              $t('gallery.folder.filter.dialog.share.expiration')
            }}</small>
            <datepicker
              :placeholder="$t('gallery.folder.filter.dialog.share.placeholder')"
              class="w-full"
              :language="langFr"
              :fullMonthName="true"
              :disabledDates="disabledDates"
              minimumView="day"
              initialView="day"
              format="dd MMMM yyyy"
              name="date"
              v-model="share.date"
            />
            <span v-if="errors.length > 0" class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
        </vs-prompt>
      </ValidationObserver>
      <vs-popup :active.sync="activePopupLinkShare" :title="$t('gallery.folder.filter.dialog.share.success.title')">
        <div class="flex flex-col items-center space-x-2">
          <p class="mb-3">
              {{ $t('gallery.folder.filter.dialog.share.link.title') }}
          </p>
          <p class="font-semibold mb-1">
              {{ $t('gallery.folder.filter.dialog.share.link.text') }}
          </p>
          <p class="text-primary mb-3">
            Ce lien expirera le {{ formatDate(dataLinkShare.date) }}
          </p>
        </div>
      </vs-popup>
    </vs-button>
  </vs-tooltip>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'
import { DateTime } from 'luxon'
export default {
  components: {
    Datepicker
  },
  computed: {
    mediasSelected () {
      return this.$store.state.media.selectedMedias
    },
    foldersSelected () {
      return this.$store.state.folder.selectedFolders
    },
    bearer () {
      return this.$store.getters['auth/accessToken']
    }
  },
  props: {
    sidebarActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      share: {
        date: '',
        folderList: [],
        mediaList: []
      },
      dataLinkShare: {},
      activePromptShare: false,
      activePopupLinkShare: false,
      langFr: fr,
      disabledDates: {
        to: new Date((Date.now() + (3600 * 1000 * 24)) - 8640000)
      }
    }
  },
  methods: {
    async acceptShare () {
      try {
        this.$vs.loading()
        this.share.date.setUTCHours(23, 59, 59)
        const { data } = await this.$store.dispatch('folder/generateShareLink', this.share)
        this.$vs.loading.close()
        this.dataLinkShare = data
        this.activePromptShare = false
        this.activePopupLinkShare = true
        this.$vs.notify({
          title: this.$t('gallery.folder.filter.dialog.share.success.title'),
          text: this.$t('gallery.folder.filter.dialog.share.success.text'),
          color: 'success'
        })
      } catch (e) {
        this.$vs.loading.close()
        this.$vs.notify({
          color: 'danger',
          text: this.$t('gallery.folder.filter.dialog.share.link.text.error', { error: e.response.data['hydra:description'] }),
          fixed: true
        })
      }
    },
    onShare () {
      this.activePromptShare = true
      this.prepareDataForShare()
    },
    prepareDataForShare () {
      this.share.folderList = this.foldersSelected.map(folder => { return folder['@id'] })
      this.share.mediaList = this.mediasSelected.map(media => { return media['@id'] })
    },
    formatDate (date) {
      let dateTime = DateTime
      dateTime = DateTime.fromISO(date)
      return dateTime.toLocaleString({
        locale: 'fr',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.vs-button.small {
  padding: 6px 3.4px 6px 6px;
}
</style>
