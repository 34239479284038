<template>
  <div class="flex items-center">
    <vs-icon
      class="block mr-3 mt-1"
      icon-pack="feather"
      icon="icon-tag"
      size="20px"
      color="primary"
    />
    <multiselect
      class="multiselect-filter"
      v-model="tagInput"
      @input="fetchFolders()"
      :multiple="true"
      :options="tags"
      @tag="addTag"
      :showLabels="false"
      :taggable="true"
      trackBy="@id"
      label="name"
      :close-on-select="true"
      :clear-on-select="false"
      :searchable="false"
      :limit="5"
      :placeholder="$t('gallery.filter.multiselect.tags.search.placeholder')"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  props: {
    tagSearch: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: { Multiselect },
  computed: {
    tags () {
      return this.$store.state.tag.tags
    }
  },
  data () {
    return {
      tagInput: []
    }
  },
  methods: {
    fetchFolders () {
      const payload = this.tagInput.map((tag) => {
        return tag.name
      })
      this.$emit('on-filter-tags', {'tags.name': payload})
    },
    async addTag (newTag) {
      this.tagInput.push(newTag)
    }
  },
  watch: {
    tagSearch: {
      handler () {
        this.tagInput.push(this.tagSearch)
        this.fetchFolders()
      }
    }
  }
}
</script>

