import axios from '@/axios'
import qs from 'qs'
import Vue from 'vue'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    medias: [],
    selectedMedias: [],
    totalMedias: 0,
    totalSelectedMedias: 0,
    loading: false,
    isAllMediasSelected: false
  },
  mutations: {
    SET_MEDIAS (_, medias) {
      const folder = this.state.folder.folder
      Vue.set(folder, 'medias', medias)
    },
    ADD_MEDIAS (state, medias) {
      const folder = this.state.folder.folder
      folder.medias.push(...medias)
      state.totalMedias = folder.medias.length
    },
    UPDATE_MEDIA (state, mediaUpdated) {
      const mediaSelectedIndex = state.selectedMedias.findIndex(
        media => media.id === mediaUpdated.id
      )
      Vue.set(state.selectedMedias, mediaSelectedIndex, mediaUpdated)
      const indexMedia = this.state.folder.folder.medias.findIndex(m => m.id === mediaUpdated.id)
      Vue.set(this.state.folder.folder.medias, indexMedia, mediaUpdated)
    },
    REMOVE_MEDIA (state, mediaId) {
      const folder = this.state.folder.folder
      const indexMedia = folder.medias.findIndex(media => media.id === mediaId)
      folder.medias.splice(indexMedia, 1)
      state.totalMedias = folder.medias.length
    },
    ADD_ITEM_SELECTED_MEDIAS (state, item) {
      state.selectedMedias.push(item)
      state.totalSelectedMedias++
    },
    ADD_ALL_MEDIAS (state, medias) {
      state.selectedMedias = medias
      state.totalSelectedMedias = medias.length
    },
    REMOVE_ITEM_SELECTED_MEDIAS (state, item) {
      const itemIndex = state.selectedMedias.findIndex(el => el.id === item.id)
      state.selectedMedias.splice(itemIndex, 1)
      state.totalSelectedMedias--
    },
    CLEAR_SELECTED_MEDIAS (state) {
      state.selectedMedias = []
      state.totalSelectedMedias = 0
    },
    SET_LOADING (state, isLoading) {
      state.loading = isLoading
    },
    SET_ALL_MEDIAS_SELECTED (state, isAllSelected) {
      state.isAllMediasSelected = isAllSelected
    }
  },
  actions: {
    async searchMedias ({ commit }, payload) {
      commit('SET_LOADING', true)
      try {
        let url = '/gallery/media'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        const { data } = await axios.get(url)
        commit('SET_MEDIAS', data['hydra:member'])
        return data['hydra:member']
      } catch (err) {
        console.log(err) // eslint-disable-line no-console
      }
    },
    async addMedia ({ commit }, media) {
      commit('SET_LOADING', true)
      try {
        const mediaResponse = await axios.post('/gallery/media', media)
        return mediaResponse
      } catch (err) {
        return err.response
      }
    },
    updateMedia ({ commit }, media) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .put(`/gallery/media/${media.id}`, media)
          .then(response => {
            commit('UPDATE_MEDIA', response.data)
            commit('SET_LOADING', false)
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            reject(error)
          })
      })
    },
    updateMedias ({ commit }, medias) {
      return new Promise((resolve, reject) => {
        medias.map(media => {
          commit('SET_LOADING', true)
          axios
            .put(`/gallery/media/${media.id}`, media)
            .then(response => {
              commit('UPDATE_MEDIA', response.data)
              commit('SET_LOADING', false)
              resolve(response)
            })
            .catch(error => {
              commit('SET_LOADING', false)
              reject(error)
            })
        })
      })
    },
    removeMedia ({ commit }, mediasSelected) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        mediasSelected.map(media => {
          axios
            .delete(`/gallery/media/${media.id}`)
            .then(response => {
              commit('REMOVE_MEDIA', media.id)
              resolve(response)
            })
            .catch(error => {
              commit('SET_LOADING', false)
              reject(error)
            })
        })
        commit('SET_LOADING', false)
      })
    }
  },
  getters: {
    getFirstMediaSelected: state => {
      return state.selectedMedias[0]
    }
  }
}
