import axios from '@/axios'
import qs from 'qs'
import Vue from 'vue'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    folders: [],
    folder: {},
    lastFetchFolders: [],
    selectedFolders: [],
    totalSelectedFolders: 0,
    totalFolders: 0,
    loading: false,
    isAllFoldersSelected: false
  },
  mutations: {
    SET_FOLDERS (state, folders) {
      state.folders = folders['hydra:member']
      state.folders.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      state.totalFolders = folders['hydra:totalItems']
    },
    SET_FOLDER (state, folder) {
      state.folder = folder
      state.folder.childrenFolders.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
    SET_CHILDREN_FOLDERS (state, folder) {
      state.folder.childrenFolders = folder['hydra:member']
    },
    ADD_FOLDER (state, folder) {
      if (folder.parentFolder === null) {
        state.folders.push(folder)
      } else {
        state.folder.childrenFolders.push(folder)
      }
      state.totalFolders++
    },
    UPDATE_FOLDER (state, folderUpdated) {
      const folderSelectedIndex = state.selectedFolders.findIndex(
        folder => folder.id === folderUpdated.id
      )
      Vue.set(state.selectedFolders, folderSelectedIndex, folderUpdated)

      const folderIndex = state.folders.findIndex(
        folder => folder.id === folderUpdated.id
      )
      if (folderIndex >= 0) {
        Vue.set(state.folders, folderIndex, folderUpdated)
        Vue.set(state.folders, folderUpdated.name, state.folders[folderIndex].name)
        Vue.set(state.folders, folderUpdated.date, state.folders[folderIndex].date)
        state.folders.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      } else {
        const childrenIndex = state.folder.childrenFolders.findIndex(child => child.id === folderUpdated.id)
        Vue.set(state.folder.childrenFolders, childrenIndex, folderUpdated)
        Vue.set(state.folder.childrenFolders, folderUpdated.name, state.folder.childrenFolders[childrenIndex].name)
        Vue.set(state.folder.childrenFolders, folderUpdated.date, state.folder.childrenFolders[childrenIndex].date)
        state.folder.childrenFolders.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      }
    },
    REMOVE_FOLDER (state, folderId) {
      const folderIndex = state.folders.findIndex(folder => folder.id === folderId)
      if (folderIndex >= 0) {
        state.folders.splice(folderIndex, 1)
      } else {
        const childrenIndex = state.folder.childrenFolders.findIndex(child => child.id === folderId)
        state.folder.childrenFolders.splice(childrenIndex, 1)
      }
      state.totalFolders--
      state.totalSelectedFolders = 0
      state.selectedFolders = []
    },
    ADD_ITEM_SELECTED_FOLDERS (state, item) {
      state.selectedFolders.push(item)
      state.totalSelectedFolders++
    },
    REMOVE_ITEM_SELECTED_FOLDERS (state, item) {
      const itemIndex = state.selectedFolders.findIndex(el => el.id === item.id)
      state.selectedFolders.splice(itemIndex, 1)
      state.totalSelectedFolders--
    },
    CLEAR_SELECTED_FOLDERS (state) {
      state.selectedFolders = []
      state.totalSelectedFolders = 0
    },
    SET_LOADING (state, isLoading) {
      state.loading = isLoading
    },
    ADD_ALL_FOLDERS (state, folders) {
      state.selectedFolders = folders
      state.totalSelectedFolders = folders.length
    },
    SET_ALL_FOLDERS_SELECTED (state, isAllSelected) {
      state.isAllFoldersSelected = isAllSelected
    },
    SET_LAST_FETCH_FOLDERS (state, folders) {
      if (folders) {
        state.lastFetchFolders = folders['hydra:member']
      }
      state.folders = state.lastFetchFolders
    }
  },
  actions: {
    fetchFolders ({ commit, rootGetters }, payload) {
      if (rootGetters.hasCancelToken('fetchFolders')) {
        rootGetters.cancelToken('fetchFolders').cancel()
      }

      commit('SET_CANCEL_TOKEN', 'fetchFolders', { root: true })

      commit('SET_LOADING', true)

      return new Promise((resolve, reject) => {
        let url = '/gallery/folders'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios
          .get(url, {
            cancelToken: rootGetters.cancelToken('fetchFolders').token
          })
          .then(response => {
            if (['parentFolder.id'] in payload) {
              commit('SET_CHILDREN_FOLDERS', response.data)
            } else {
              commit('SET_FOLDERS', response.data)
            }
            if (payload['exists[parentFolder]'] === false) {
              commit('SET_LAST_FETCH_FOLDERS', response.data)
            }
            commit('SET_LOADING', false)
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            reject(error)
          })
      })
    },
    fetchFolder ({ commit }, folder) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/gallery/folders/${folder.id}`)
          .then(response => {
            commit('SET_FOLDER', response.data)
            commit('SET_LOADING', false)
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            reject(error)
          })
      })
    },
    addFolder ({ commit }, folder) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post('/gallery/folders', folder)
          .then(response => {
            commit('ADD_FOLDER', response.data)
            commit('SET_LOADING', false)
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            reject(error)
          })
      })
    },
    updateFolder ({ commit }, folder) {
      return new Promise((resolve, reject) => {
        let folderUpdate = {}
        if (folder.parentFolder instanceof Object) {
          folderUpdate = {
            ...folder,
            tags: folder.tags.map(tag => tag['@id']),
            parentFolder: folder.parentFolder['@id']
          }
        } else {
          folderUpdate = {
            ...folder,
            tags: folder.tags.map(tag => tag['@id'])
          } 
        }
        commit('SET_LOADING', true)
        axios
          .put(`/gallery/folders/${folder.id}`, folderUpdate)
          .then(response => {
            commit('UPDATE_FOLDER', response.data)
            commit('SET_LOADING', false)
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            reject(error)
          })
      })
    },
    removeFolder ({ commit }, foldersSelected) {
      return new Promise((resolve, reject) => {
        foldersSelected.map(folder => {
          commit('SET_LOADING', true)
          axios
            .delete(`/gallery/folders/${folder.id}`)
            .then(response => {
              commit('REMOVE_FOLDER', folder.id)
              commit('SET_LOADING', false)
              resolve(response)
            })
            .catch(error => {
              commit('SET_LOADING', false)
              reject(error)
            })
        })
      })
    },
    generateShareLink ({ commit }, shareData) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/gallery/shares', shareData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            commit('SET_LOADING', false)
            reject(error)
          })
        commit('SET_LOADING', false)
      })
    }
  },
  getters: {
    getFirstFolderSelected: state => {
      return state.selectedFolders[0]
    }
  }
}
