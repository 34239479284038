<template>
  <div v-if="folder && folder.id" id="gallery-card" class="main-gallery-card">
    <upload-media v-if="$acl.check('galleryUpload')" @on-media-added="fetchFolder(folder)" :folderId="folder.id" />
    <new-folder
      class="mt-2"
      @on-folder-added="fetchFolder(folder)"
      @uncheckFolders="uncheckFolders"
      :folderId="folder.id"
    />
    <div class="lg:flex flex-wrap justify-between">
      <div class="flex items-center flex-wrap lg:flex-no-wrap space-x-2">
        <filter-search
          class="mb-2 px-2 w-full"
          @on-filter-search="filterSearch($event)"
          :searchQueryProps="payloadFilterSearch.name"
          :searchQueryActive="searchQueryActive"
          @remove-search="removeSearch"
        />
      </div>
      <div class="flex flex-wrap lg:flex-no-wrap justify-between">
        <filter-date-picker
          class="w-full items-center lg:w-auto mb-2 px-2"
          @on-filter-date="filterDate($event)"
        />
        <filter-tags
          class="w-full items-center lg:w-auto mb-2 px-2"
          @on-filter-tags="filterTags($event)"
        />
      </div>
      <div class="flex py-2">
          <select-all-folders
            v-if="folder.childrenFolders && folder.childrenFolders.length > 0"
            class="lg:w-auto"
            :folders="folder.childrenFolders"
          />
          <select-all-medias
            v-if="folder.medias.length > 0"
            class="lg:w-auto"
            :medias="folder.medias"
          />
          <share-button class="pl-2" :sidebarActive="sidebarActive" />
        </div>
    </div>
    <breadcrumb-folder
      class="my-2"
      :folder="folder"
      @on-return-to-last-folder="returnToLastFolder($event)"
    />
    <vs-card
      ref="vsCardSidebar"
      id="vsCardSidebar"
      :style="[sidebarActive ? { paddingRight: '270px !important' } : '']"
      class="vs-con-loading__container px-3"
    >
      <div class="folder-grid gap-3">
        <div
          :key="folder.id"
          v-for="folder in folder.childrenFolders"
          class="folders mt-5 mb-20 cursor-pointer"
          @click="fetchFolder(folder)"
        >
          <input
            ref="folderCheckbox"
            type="checkbox"
            :checked="isAllFoldersSelected"
            :value="folder"
            @change="selectItem($event, folder, 'FOLDERS')"
            @click.stop
            class="btn-selected-folder cursor-pointer"
          />
          <div class="inner-folder px-3 py-1 flex flex-wrap h-full">
            <div
              class="px-1 mt-2"
              v-for="media in folder.medias.slice(0, 6)"
              :key="media.id"
            >
              <img
                :src="`${media.thumbnailUrl}?bearer=${bearer}`"
                class="media-preview object-cover w-10 h-10 rounded"
                alt=""
              />
            </div>
          </div>
          <div class="pt-4">
            <h4 class="description text-primary font-medium md:text-base">
              {{ folder.name }}
            </h4>
            <p class="description text-base pt-1">
              {{ folder.date | formatDate }}
            </p>
            <p>
              <span
                v-if="folder.countMedias > 0"
                class="description md:text-xs"
              >
                Fichiers : {{ folder.countMedias }}
              </span>
              <span
                v-if="folder.childrenFolders && folder.childrenFolders.length > 0"
                class="description md:text-xs"
              >
                | Dossiers : {{ folder.childrenFolders.length }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <silent-box
          v-if="folder.medias.length > 0"
          ref="silentbox"
          class="gap-3 mt-5"
          :key="componentKey"
          :gallery="galleryBox"
          :lazy-loading="true"
          :class="isVerticalNavMenuActive ? 'main-sidebar-is-active' : ''"
        >
          <template v-slot:silentbox-item="{ silentboxItem }">
            <input
              type="checkbox"
              :checked="isAllMediasSelected"
              @change="selectItem($event, silentboxItem, 'MEDIAS')"
              @click.stop
              class="checkbox-media btn-selected-media cursor-pointer"
            />
            <img
              v-if="silentboxItem.type === 'picture'"
              class="media"
              :lazy-loading="true"
              :src="`${silentboxItem.thumbnailUrl}?bearer=${bearer}`"
              :height="silentboxItem.thumbnailHeight"
              :width="silentboxItem.thumbnailWidth"
            />
            <video
              v-if="silentboxItem.type === 'video'"
              class="media"
              :src="silentboxItem.src || silentboxItem.file.src"
              :height="silentboxItem.thumbnailHeight"
              :width="silentboxItem.thumbnailWidth"
            />
            <p class="media-name">{{ silentboxItem.name | str_limit(21) }}</p>
          </template>
        </silent-box>
      </div>
      <sidebar
        @refreshGallery="fetchFolder(folder)"
        @uncheckFolders="uncheckFolders"
        @uncheckMedias="uncheckMedias"
        @cancelSidebar="cancelSidebar"
      />
    </vs-card>
  </div>
</template>

<script>
import UploadMedia from '../gallery-form/UploadMedia'
import NewFolder from './NewFolder'
import Sidebar from './Sidebar'
import FilterDatePicker from './FilterDatePicker'
import FilterTags from './FilterTags'
import FilterSearch from './FilterSearch'
import SelectAllFolders from './SelectAllFolders.vue'
import SelectAllMedias from './SelectAllMedias.vue'
import BreadcrumbFolder from './BreadcrumbFolder'
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
import ShareButton from './ShareButton'
import { DateTime } from 'luxon'
export default {
  computed: {
    mediasSelected () {
      return this.$store.state.media.selectedMedias
    },
    foldersSelected () {
      return this.$store.state.folder.selectedFolders
    },
    isAllFoldersSelected () {
      return this.$store.state.folder.isAllFoldersSelected
    },
    isAllMediasSelected () {
      return this.$store.state.media.isAllMediasSelected
    },
    isVerticalNavMenuActive () {
      return this.$store.state.isVerticalNavMenuActive
    },
    tags () {
      return this.$store.state.tag.tags
    },
    sidebarActive () {
      if (
        this.$store.state.folder.totalSelectedFolders > 0 ||
        this.$store.state.media.totalSelectedMedias > 0
      ) {
        return true
      }
      return false
    },
    bearer () {
      return this.$store.getters['auth/accessToken']
    }
  },
  data () {
    return {
      folder: {},
      galleryBox: [],
      payloadFilters: [],
      payloadFilterDate: {},
      payloadFilterTags: {},
      payloadFilterSearch: {},
      componentKey: 0,
      activePromptShare: false,
      searchQueryActive: false
    }
  },
  methods: {
    filterDate (payload) {
      payload && payload.date ? this.payloadFilterDate = payload : this.payloadFilterDate = {}
    },
    filterTags (payload) {
      payload && payload['tags.name'] ? (this.payloadFilterTags = payload) : (this.payloadFilterTags = {})
    },
    filterSearch (payload) {
      payload && payload['name'] ? (this.payloadFilterSearch = payload) : (this.payloadFilterSearch = {})
    },
    async searchItem () {
      this.payloadFilterSearch.name ? (this.searchQueryActive = true) : (this.searchQueryActive = false)
      this.$vs.loading({
        container: '#gallery-card',
        scale: 0.6
      })
      this.payloadFilters = {
        'exists[parentFolder]': true,
        'parentFolder.id': this.folder.id,
        ...this.payloadFilterDate,
        ...this.payloadFilterTags,
        ...this.payloadFilterSearch
      }
      await this.$store.dispatch('folder/fetchFolders', this.payloadFilters)

      this.payloadFilters = {
        'folder': this.folder['@id'],
        'createdAt': this.payloadFilterDate.date,
        ...this.payloadFilterTags,
        ...this.payloadFilterSearch
      }
      await this.$store.dispatch('media/searchMedias', this.payloadFilters)
      await this.refreshGalleryBox()

      this.$vs.loading.close('#gallery-card > .con-vs-loading')
    },
    removeSearch () {
      this.searchQueryActive = false
      this.payloadFilterSearch.name = ''
      this.fetchFolder(this.folder)
    },
    async fetchFolder (folder) {
      this.searchQueryActive = false
      this.payloadFilterSearch.name = ''
      this.cancelSidebar()
      try {
        const response = await this.$store.dispatch(
          'folder/fetchFolder',
          folder
        )
        this.folder = await response.data
        await this.refreshGalleryBox()
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: this.$t('gallery.folder.fetch.error.notify.title'),
          text: this.$t('gallery.media.form.submit.error.notify.text', {
            error: error.response.data['hydra:description']
          }),
          fixed: true
        })
      }
    },
    async refreshGalleryBox () {
      this.galleryBox = await this.folder.medias.map(media => {
        return {
          ...media,
          src: `${media.fileUrl}?bearer=${this.bearer}`,
          thumbnailWidth: '150px',
          thumbnailHeight: '150px',
          thumbnailUrl: media.thumbnailUrl,
          name: media.name,
          id: media.id,
          thumbnailFile: media.type === 'picture' ? media.thumbnailFile['@id'] : null
        }
      })
        .sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
    },
    shareMediasDialog () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('administration.grouplist.table.actions.delete.dialog.title'),
        text: this.$t('administration.grouplist.table.actions.delete.dialog.text'),
        accept: this.deleteRecord,
        acceptText: this.$t('administration.grouplist.table.actions.delete.dialog.acceptText'),
        cancelText: this.$t('administration.grouplist.table.actions.delete.dialog.cancelText')
      })
    },
    async returnToLastFolder (lastFolder) {
      this.$store.commit('media/SET_ALL_MEDIAS_SELECTED', false)
      this.fetchFolder(lastFolder)
    },
    selectItem (e, item, type) {
      const moduleToCall = type === 'FOLDERS' ? 'folder' : 'media'
      if (e.target.checked) {
        this.$store.commit(`${moduleToCall}/ADD_ITEM_SELECTED_${type}`, item)
      } else {
        this.$store.commit(
          `${moduleToCall}/REMOVE_ITEM_SELECTED_${type}`,
          item
        )
      }
    },
    cancelSidebar () {
      this.uncheckMedias()
      this.uncheckFolders()
      this.$store.commit('folder/SET_ALL_FOLDERS_SELECTED', false)
      this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
      this.$store.commit('media/SET_ALL_MEDIAS_SELECTED', false)
      this.$store.commit('media/CLEAR_SELECTED_MEDIAS')
    },
    resetFilter () {
      this.payloadFilters = []
      this.payloadFilterDate = {}
      this.payloadFilterTags = {}
      this.payloadFilterSearch = {}
    },
    uncheckFolders () {
      if (this.foldersSelected.length > 0) {
        this.$refs.folderCheckbox.map(folder => {
          folder.checked = false
        })
      }
    },
    uncheckMedias () {
      if (this.mediasSelected.length > 0 || this.isAllMediasSelected) {
        const checkboxMedia = this.$refs.silentbox.$el.querySelectorAll(
          '.checkbox-media'
        )
        checkboxMedia.forEach(checkbox => {
          checkbox.checked = false
        })
      }
    }
  },
  watch: {
    payloadFilterDate () {
      this.searchItem()
    },
    onShare () {
      this.activePromptShare = true
    },
    formatDate (date) {
      let dateTime = DateTime
      dateTime = DateTime.fromISO(date)
      return dateTime.toLocaleString({
        locale: 'fr',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
    },
    payloadFilterTags () {
      this.searchItem()
    },
    payloadFilterSearch () {
      this.searchItem()
    }
  },
  async mounted () {
    await this.fetchFolder({ id: this.$route.params.folderId })
  },
  mixins: [moduleFolderMixin, moduleMediaMixin],
  components: {
    UploadMedia,
    NewFolder,
    BreadcrumbFolder,
    Sidebar,
    FilterDatePicker,
    FilterTags,
    FilterSearch,
    SelectAllFolders,
    SelectAllMedias,
    ShareButton
  }
}
</script>

<style lang="scss">
.is-mobile {
  max-width: 48%;
}
.is-large {
  max-width: 100% !important;
}
.con-select .vs-select--input {
  padding: 7px;
}
.folder-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  justify-content: space-between;
}
.folders {
  width: 150px;
  max-width: 150px;
  height: 105px;
  position: relative;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.048), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(53, 53, 53, 0.356);
  border-radius: 0 6px 6px 6px;

  &:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 0px;
    width: 50%;
    height: 12px;
    border-radius: 0 15px 0 0;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(109, 109, 109, 0.356);
  }

  .inner-folder {
    max-width: 150px;
  }
}
#vsCardSidebar {
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  .vs-card--content {
    padding: 25px 15px;
  }
}
.btn-selected-folder {
  position: absolute;
  right: -2px;
  width: 23px;
  height: 21px;
  top: -1px;
}
.main-sidebar-is-active {
  #silentbox-overlay {
    left: 260px;
    width: calc(100% - 260px);
  }
}
.vs-sidebar--background {
  display: none;
}
#silentbox-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  justify-content: space-between;
  > .silentbox-item {
    position: relative;
    text-decoration: none;
    & .btn-selected-media {
      position: absolute;
      top: 0;
      right: -1px;
      width: 23px;
      height: 21px;
      z-index: 1;
    }
    & img {
      border-radius: 5px;
      object-fit: cover;
    }
    p {
      color: white;
      position: absolute;
      height: 17px;
      right: 0;
      bottom: 4px;
      left: 0;
      background: #85460386;
      border-radius: 0 0 5px 5px;
      font-size: 0.8rem;
      font-weight: 400;
      text-align: center;
      margin: 0;
      padding: 0;
    }
  }
  video.silentbox-video__embed {
    width: 80vw;
  }

  #silentbox-overlay__background {
    background: rgba(0, 0, 0, 0.3);
  }
}
.card-header {
  .divider {
    margin: 10px 0;
  }
}
@media screen and (max-width: 768px) {
  #vsCardSidebar {
    .vs-card--content {
      padding: 0 5px;
    }
  }
  #silentbox-gallery {
    grid-template-columns: repeat(auto-fill, 60px);
    > .silentbox-item {
      & img {
        width: 60px;
        height: 60px;
      }
      & video {
        width: 60px;
        height: 60px;
      }
    }
  }
  #vsCardSidebar > div > div.folder-grid.gap-3 > div > div.description.pt-4 {
    font-size: 0.6rem !important;
  }
  .folder-grid {
    grid-template-columns: repeat(auto-fill, 60px) !important;
  }
  .folders {
    width: 60px;
    max-width: 60px;
    height: 50px;

    &:before {
      top: -7px;
      height: 5px;
    }

    .inner-folder {
      padding: 0 2px !important;
      min-width: 60px;
    }
  }
  .description {
    font-size: 0.6rem !important;
  }
  .media-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 10px !important;
    height: 10px !important;
  }
}
</style>
