<template>
  <div class="" @click="isSelectedAllFolders">
    <vs-chip
      :color="
        isAllFoldersSelected && selectedFolders.length > 0 ? 'primary' : ''
      "
      class="w-max cursor-pointer"
    >
      <vs-avatar icon="icon-check" icon-pack="feather" color="primary" />
      <p class="text-xs">{{ $t('gallery.folder.button.folders.select.all') }}</p>
    </vs-chip>
  </div>
</template>

<script>
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
const cloneDeep = require('lodash.clonedeep')
export default {
  mixins: [moduleFolderMixin, moduleMediaMixin],
  props: {
    folders: {
      type: Array,
      required: true
    }
  },
  computed: {
    selectedFolders () {
      return this.$store.state.folder.selectedFolders
    },
    isAllFoldersSelected () {
      return this.$store.state.folder.isAllFoldersSelected
    }
  },
  methods: {
    isSelectedAllFolders () {
      this.$store.commit('folder/SET_ALL_FOLDERS_SELECTED', !this.isAllFoldersSelected)
      if (this.isAllFoldersSelected) {
        this.$store.commit('folder/ADD_ALL_FOLDERS', cloneDeep(this.folders))
      } else {
        this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
      }
    }
  }
}
</script>

<style lang="scss">
.con-vs-chip {
  margin-bottom: 0;
}
</style>
