<template>
  <div id="gallery-card" v-if="mediasSelected.length > 0">
    <div class="mt-2" slot="header">
      <div class="flex items-center mb-4">
        <h1 class="text-base">
          {{ mediasSelected.length }}
          <span>{{
            mediasSelected.length > 1
              ? $t("gallery.media.sidebar.selected.medias")
              : $t("gallery.media.sidebar.selected.media")
          }}</span>
        </h1>
        <vs-button v-if="$acl.check('galleryUpload')"
          @click="openDialogRemoveMedias"
          size="small"
          color="danger"
          icon="delete"
          class="ml-auto"
        />
      </div>
      <div
        v-for="media in mediasSelected.slice(0, 6)"
        :key="media.id"
        class="flex flex-col"
        :class="isManyMediasSelected ? 'mb-3' : ''"
      >
        <div class="flex mb-2">
          <feather-icon icon="ImageIcon" svgClasses="h-4 w-4" class="pr-2" />
          <h4 class="text-base">
            {{ media.name }}
          </h4>
        </div>
        <div class="flex flex-wrap">
          <div v-for="mediaTags in media.tags" :key="mediaTags.id">
            <vs-chip color="primary">
              {{ `#${mediaTags.name}` }}
            </vs-chip>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div v-for="mediaRole in media.roles" :key="mediaRole.id" class="flex flex-wrap my-1 mr-1">
            <vs-chip class="mb-1" color="dark" v-if="mediaRole">{{ $t(`gallery.roles.checkbox.${mediaRole}`) }}</vs-chip>
            <vs-chip color="dark" v-else>{{ $t('gallery.roles.checkbox.admin') }}</vs-chip>
          </div>
        </div>
      </div>
      <p v-if="mediasSelected.length > 6" class="mb-1 font-medium text-base">
        {{ $t("gallery.media.sidebar.selected.medias.more", { media: mediasSelected.length - 6}) }}
      </p>
    </div>
    <ValidationObserver
      v-if="isManyMediasSelected"
      v-slot="{ invalid, handleSubmit }"
    >
      <form class="flex flex-col space-y-3" @submit.prevent="handleSubmit(updateMedias)">
        <ValidationProvider
          :name="$t('gallery.folder.form.input.description')"
          rules="max:100"
          class="w-full mb-3"
          v-slot="{ errors }"
        >
          <div class="flex justify-between items-center">
            <small class="label-form">{{
              $t("gallery.folder.form.input.description")
            }}</small>
            <vx-tooltip
              v-if="userHasRules"
              :text="$t('gallery.sidebar.form.tooltip.description')"
              class="pt-1"
            >
              <vs-icon icon="help" class="cursor-pointer" />
            </vx-tooltip>
          </div>
          <vs-textarea
            type="text"
            :placeholder="mediaClone.description ? mediaClone.description : $t('gallery.media.form.input.description.many_selected.no_rules')"
            v-model="descriptionAllMedias"
            :disabled="!userHasRules"
          />
          <span v-if="errors.length > 0" class="error-class">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="flex justify-between items-center">
          <small class="label-form">
            {{ $t("gallery.sidebar.form.input.tags") }}
          </small>
          <vx-tooltip v-if="userHasRules" :text="$t('gallery.sidebar.form.tooltip.tags')">
            <vs-icon icon="help" class="cursor-pointer" />
          </vx-tooltip>
        </div>
        <multiselect
          v-model="tagInput"
          class="multiselect-custom"
          :multiple="true"
          :options="tags"
          :showLabels="false"
          :taggable="true"
          @tag="addTag"
          trackBy="@id"
          label="name"
          :disabled="!userHasRules"
          :placeholder="$t('gallery.upload.form.multiselect.tags.choose')"
          :tag-placeholder="$t('gallery.upload.form.multiselect.tags.add')"
          :close-on-select="false"
        />
        <vs-collapse class="px-1">
          <vs-collapse-item :open="!isChangeRoleActive" :not-arrow="true">
            <div class="flex items-center space-x-2" slot="header">
              <span class="text-sm">Garder les mêmes permissions</span>
              <vs-switch color="success" v-model="isChangeRoleActive" vs-icon-on="done" vs-icon-off="clear" />
            </div>
            <div class="flex items-center justify-between">
              <p class="text-sm">Permissions</p>
            </div>
            <role-checkbox :roles="roles" :is-many-medias-selected="isManyMediasSelected" @on-check-role-sidebar="changeRoleInput($event)">
          </role-checkbox>
        </vs-collapse-item>
        </vs-collapse>
        <div v-if="userHasRules" class="flex flex-col space-y-2 mt-4">
          <vs-button button="submit" :disabled="invalid">
            {{ $t("gallery.media.form.submit.button") }}
          </vs-button>
          <vs-button @click="resetForm" :disabled="invalid">
            {{ $t("gallery.media.form.cancel.button") }}
          </vs-button>
        </div>
      </form>
    </ValidationObserver>
    <ValidationObserver
      v-if="isOneMediaSelected"
      v-slot="{ invalid, handleSubmit }"
    >
      <form
        class="flex flex-col space-y-3"
        @submit.prevent="handleSubmit(updateMedia)"
      >
        <ValidationProvider
          :name="$t('gallery.folder.form.input.name')"
          class="w-full"
          rules="required"
          :is-valid="!invalid"
          :disabled="!userHasRules"
          v-slot="{ errors }"
        >
          <vs-input
            type="text"
            :label="$t('gallery.media.form.input.name')"
            :placeholder="mediaClone.name"
            :disabled="!userHasRules"
            v-model="mediaClone.name"
            class="label-form pl-0 w-full"
          />
          <span v-if="errors.length > 0" class="error-class">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          :name="$t('gallery.folder.form.input.description')"
          class="w-full"
          rules="max:100"
          v-slot="{ errors }"
        >
          <small class="label-form">{{
            $t("gallery.folder.form.input.description")
          }}</small>
          <vs-textarea
            type="text"
            :placeholder="mediaClone.description ? mediaClone.description : $t('gallery.media.form.input.description.many_selected.no_rules')"
            :disabled="!userHasRules"
            v-model="mediaClone.description"
          />
          <span v-if="errors.length > 0" class="error-class">{{ errors[0] }}</span>
        </ValidationProvider>
        <div>
          <small class="label-form">{{
            $t("gallery.sidebar.form.input.tags")
          }}</small>
          <multiselect
            ref="multiselect"
            v-model="mediaClone.tags"
            class="multiselect-custom"
            :multiple="true"
            :options="tags"
            :showLabels="false"
            :taggable="true"
            @tag="addTag"
            trackBy="@id"
            :disabled="!userHasRules"
            label="name"
            :placeholder="$t('gallery.upload.form.multiselect.tags.choose')"
            :tag-placeholder="$t('gallery.upload.form.multiselect.tags.add')"
            :close-on-select="false"
          />
          <span class="error-class">{{ tagError }}</span>
        </div>
        <role-checkbox :media="mediaClone" :roles="roles">
          <template v-slot:sidebar>
            <div class="flex items-center">
              <div class="flex items-center">
                <small class="label-form">{{ $t("gallery.folder.sidebar.input.roles") }}</small>
              </div>
            </div>
          </template>
        </role-checkbox>
        <vs-input
          v-if="hasFileDateTime"
          class="w-full label-form pl-0"
          disabled
          :label="$t('gallery.media.form.input.disabled.date_time')"
          :placeholder="Date(mediaClone.file.fileMetaDatas.exif.FILE.FileDateTime) | formatDate"
        />
        <vs-input
          v-if="hasDimensions"
          class="w-full label-form pl-0"
          disabled
          :label="$t('gallery.media.form.input.disabled.dimensions')"
          :placeholder="
            `${mediaClone.file.fileDimensions[0]}x${mediaClone.file.fileDimensions[1]}`
          "
        />
        <vs-input
          v-if="hasExifModel"
          class="w-full label-form pl-0"
          disabled
          :label="$t('gallery.media.form.input.disabled.model')"
          :placeholder="mediaClone.file.fileMetaDatas.exif.IFD0.Model"
        />
        <vs-input
          class="w-full label-form pl-0"
          disabled
          :label="$t('gallery.media.form.input.disabled.file_size')"
          :placeholder="hasFileSize"
        />
        <vs-input
          v-if="isVideoFile"
          class="w-full label-form pl-0"
          disabled
          :label="$t('gallery.media.form.input.disabled.duration')"
          :placeholder="mediaClone.file.fileMetaDatas.ID3.playtime_string + ' min'"
        />
        <div v-if="userHasRules" class="flex flex-col space-y-2 mt-4">
          <vs-button button="submit" :disabled="invalid">
            {{ $t("gallery.media.form.submit.button") }}
          </vs-button>
          <vs-button @click="resetForm" :disabled="invalid">
            {{ $t("gallery.media.form.cancel.button") }}
          </vs-button>
        </div>

        <vs-divider />

        <a :href="mediaSelected.src" download>
          <vs-button class="w-full" type="border" icon-pack="feather" icon="icon-download">
            {{ $t("gallery.media.form.download.button") }}
          </vs-button>
        </a>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import moduleTagsMixin from '@/store/tag/moduleTagMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import RoleCheckbox from '../gallery-list/RoleCheckbox'
import Multiselect from 'vue-multiselect'
const cloneDeep = require('lodash.clonedeep')
export default {
  mixins: [moduleTagsMixin, moduleMediaMixin, moduleFolderMixin],
  components: {
    Multiselect,
    RoleCheckbox
  },
  computed: {
    mediaSelected () {
      return this.$store.getters['media/getFirstMediaSelected']
    },
    mediasSelected () {
      return this.$store.state.media.selectedMedias
    },
    tags () {
      return this.$store.state.tag.tags
    },
    isOneMediaSelected () {
      return this.$store.state.media.totalSelectedMedias === 1
    },
    isManyMediasSelected () {
      return this.$store.state.media.totalSelectedMedias > 1
    },
    roles () {
      return this.$store.state.AppActiveUser.roles
    },
    hasFileDateTime () {
      if (this.mediaClone.file?.fileMetaDatas?.exif?.FILE?.FileDateTime) {
        return true
      }
      return false
    },
    hasDimensions () {
      if (this.mediaClone.file?.fileDimensions?.length > 0) {
        return true
      }
      return false
    },
    hasExifModel () {
      if (this.mediaClone.file?.fileMetaDatas?.exif?.IFD0?.Model) {
        return true
      }
      return false
    },
    hasFileSize () {
      if (this.mediaClone.file?.fileSize) {
        const fileSizeFormat = this.mediaClone.file.fileSize / (1024 * 1024)
        return `${parseFloat(fileSizeFormat).toFixed(2)} Mo`
      }
      return false
    },
    isVideoFile () {
      if (this.mediaClone.file?.fileMimeType?.includes('video')) {
        return true
      }
      return false
    },
    hasDuration () {
      if (this.mediaClone.file?.fileMetaDatas?.ID3?.playtime_string) {
        return true
      }
      return false
    },
    userHasRules () {
      if (this.roles.includes('ROLE_GALLERY_MARKETING')) {
        return true
      }
      if (this.roles.includes('ROLE_ADMIN')) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      medias: [],
      mediaClone: {},
      tagInput: [],
      roleInput: [],
      roleInputMedias: [],
      isChangeRoleActive: true,
      descriptionAllMedias: '',
      tagError: ''
    }
  },
  methods: {
    async updateMedia () {
      try {
        this.mediaClone.file = this.mediaClone.file['@id']
        this.mediaClone.tags = this.mediaClone.tags.map(tag => tag['@id'])
        await this.$store.dispatch('media/updateMedia', this.mediaClone)
        this.$emit('refreshGallery')
        this.$emit('uncheckMedias')
        this.$vs.notify({
          color: 'success',
          text: this.$t('gallery.media.form.update.media.success.notify.text', {
            name: this.mediaClone.name
          })
        })
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: this.$t('gallery.media.form.update.media.error.notify.title'),
          text: this.$t('gallery.media.form.update.media.error.notify.text', {
            error: error.response.data['hydra:description']
          }),
          fixed: true
        })
      }
    },
    async updateMedias () {
      try {
        await this.medias.map(media => {
          media.file = media.file['@id']
          media.description = this.descriptionAllMedias
          this.tagInput.map(tag => { media.tags.push(tag) })
          media.tags = media.tags.map(tag => tag['@id'])
          if (!this.isChangeRoleActive) {
            media.roles = [...new Set([...media.roles, ...this.roleInputMedias])]
          }
          if (typeof media.thumbnailFile === 'object') {
            media.thumbnailFile = media.thumbnailFile['@id']
          }
        })
        await this.$store.dispatch('media/updateMedias', this.medias)
        this.tagInput = []
        this.$emit('refreshGallery')
        this.$emit('uncheckMedias')
        this.$vs.notify({
          color: 'success',
          text: this.$t('gallery.media.form.update.medias.success.notify.text')
        })
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: this.$t('gallery.media.form.update.medias.error.notify.title'),
          text: this.$t('gallery.media.form.update.medias.error.notify.text', {
            error: error.response.data['hydra:description']
          }),
          fixed: true
        })
      } finally {
        this.isChangeRoleActive = true
      }
    },
    resetForm () {
      this.mediaClone = cloneDeep(this.mediaSelected)
      this.roleInput = []
      this.roleInputMedias = []
      this.isChangeRoleActive = true
      this.$emit('cancelSidebar')
    },
    changeRoleInput (roleArray) {
      this.roleInputMedias = roleArray
    },
    async addTag (newTag) {
      if (newTag.length >= 30) {
        this.$refs.multiselect.isOpen = false
        this.tagError = this.$t('gallery.upload.form.input.tags.max_value')
        return
      }
      this.tagError = ''
      const { data } = await this.$store.dispatch('tag/addTag', {
        name: newTag
      })
      this.mediaClone.tags.push(data)
    },
    openDialogRemoveMedias () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('gallery.media.sidebar.delete.selected.title'),
        text: this.$t('gallery.media.sidebar.delete.selected.text'),
        accept: this.removeMediasSelected,
        acceptText: this.$t('gallery.tags.table.actions.delete.dialog.acceptText'),
        cancelText: this.$t('gallery.tags.table.actions.delete.dialog.cancelText')
      })
    },
    async removeMediasSelected () {
      await this.$store.commit('media/SET_ALL_MEDIAS_SELECTED', false)
      try {
        this.$vs.loading({
          container: '#gallery-card',
          scale: 0.6,
          text: this.$i18n.t('gallery.loading.fetch.folder')
        })
        await this.$store.dispatch('media/removeMedia', this.mediasSelected)
        this.$emit('refreshGallery')
        this.$vs.loading.close('#gallery-card > .con-vs-loading')
        this.$store.commit('media/CLEAR_SELECTED_MEDIAS')
        this.$vs.notify({
          color: 'success',
          text: this.$t('gallery.media.form.remove.medias.success.notify.text')
        })
      } catch (error) {
        this.$vs.loading.close('#gallery-card > .con-vs-loading')
        this.$vs.notify({
          color: 'danger',
          title: this.$t('gallery.media.form.remove.medias.error.notify.title'),
          text: this.$t('gallery.media.form.remove.medias.error.notify.text', {
            error: error.response.data['hydra:description']
          }),
          fixed: true
        })
      }
    }
  },
  watch: {
    mediaSelected: {
      handler () {
        this.mediaClone = cloneDeep(this.mediaSelected)
      },
      deep: true,
      immediate: true
    },
    mediasSelected: {
      handler () {
        this.medias = cloneDeep(this.mediasSelected)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.vs-collapse {
  padding: 0;
  header.vs-collapse-item--header {
      display: flex;
      justify-content: flex-end;
      padding: 0;
  }
}
.vs-collapse.default .open-item .con-content--item {
  padding-right: 0;
  padding-left: 0;
}
</style>
