<template>
  <div class="breadcrumb flex text-lg">
    <span class="flex items-center pr-1" @click="returnToGallery">
      <span class="cursor-pointer md:no-underline md:hover:underline">Gallery</span>
      <feather-icon
        icon="ChevronsRightIcon"
        svgClasses="h-4 w-4"
        class="ml-1"
      />
    </span>
    <div class="flex" v-if="folder.id">
      <div
        class="flex items-center pr-1"
        v-for="(link, index) in folderLink"
        :key="link.id"
      >
        <span
          class="cursor-pointer md:no-underline md:hover:underline"
          :class="folderLinkClass(index)"
          @click="returnToParent(link, index)"
          >{{ link.name }}</span
        >
        <span v-if="index !== folderLink.length - 1">
          <feather-icon
            icon="ChevronsRightIcon"
            svgClasses="h-4 w-4"
            class="ml-1 pt-1"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
export default {
  mixins: [moduleFolderMixin, moduleMediaMixin],
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      folderLink: []
    }
  },
  methods: {
    returnToGallery () {
      this.closeSidebarAndUncheckBetweenRoute()
      this.$router.push({ name: 'gallery' })
    },
    async returnToParent (link, i) {
      if (i !== this.folderLink.length - 1) {
        this.closeSidebarAndUncheckBetweenRoute()
        this.$emit('on-return-to-last-folder', link)
      }
    },
    folderLinkClass (index) {
      return index === this.folderLink.length - 1 ? 'text-primary cursor-text font-medium md:hover:no-underline' : ''
    },
    closeSidebarAndUncheckBetweenRoute () {
      this.$store.commit('folder/SET_ALL_FOLDERS_SELECTED', false)
      this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
      this.$store.commit('media/SET_ALL_MEDIAS_SELECTED', false)
      this.$store.commit('media/CLEAR_SELECTED_MEDIAS')
    }
  },
  watch: {
    folder (newValue, oldValue) {
      if (Object.entries(oldValue).length === 0) {
        this.folderLink.push(newValue)
        return
      }
      const isExist = this.folderLink.findIndex(
        folder => folder.id === newValue.id
      )
      if (isExist === -1) {
        this.folderLink.push(newValue)
      } else {
        this.folderLink.splice(isExist + 1, this.folderLink.length - 1)
      }
    }
  },
  created () {
    this.folderLink.push(this.folder)
  }
}
</script>

<style></style>
