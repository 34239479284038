<template>
  <ValidationObserver v-slot="{ invalid, handleSubmit }" ref="form">
    <form
      id="form-loading"
      class="vs-con-loading__container"
      @submit.prevent="handleSubmit(addAllMedias($refs.fileInput))"
    >
      <vx-card class="p-2">
        <!-- File Upload -->
        <div class="flex items-center">
          <feather-icon icon="ImageIcon" class="svg-icon" svgClasses="stroke-current text-primary" />
          <h4 class="title-form">{{ $t('gallery.upload.form.input.medias') }}</h4>
        </div>
        <vs-upload
          class="upload-media mt-2"
          multiple
          ref="fileInput"
          text="+"
          :show-upload-button="false"
          accept="image/*, video/*"
        />
        <span class="error-class">{{ uploadError }}</span>
        <!-- Tags -->
        <div class="flex items-center mt-6 mb-1">
          <feather-icon icon="TagIcon" class="svg-icon" svgClasses="stroke-current text-primary" />
          <h4 class="title-form">{{ $t('gallery.upload.form.input.tags') }}</h4>
        </div>
        <multiselect
          ref="multiselect"
          class="multiselect mt-2"
          v-model="tagInput"
          :multiple="true"
          :options="tags"
          :showLabels="false"
          :taggable="true"
          @tag="addTag"
          trackBy="@id"
          label="name"
          :placeholder="$t('gallery.upload.form.multiselect.tags.choose')"
          :tag-placeholder="$t('gallery.upload.form.multiselect.tags.add')"
          :close-on-select="false"
          :limit="5"
        />
        <span class="error-class">{{ tagError }}</span>
        <!-- Roles -->
        <role-checkbox :upload="uploadDone" :roles="roles" @on-check-role="changeRoleInput($event)">
          <template v-slot:upload>
            <div class="flex items-center mt-6 mb-3">
              <feather-icon icon="UnlockIcon" class="svg-icon" svgClasses="stroke-current text-primary" />
              <h4 class="title-form">{{ $t("gallery.upload.form.input.roles") }}</h4>
            </div>
          </template>
        </role-checkbox>
        <div class="mt-3 flex flex-wrap items-center justify-end">
          <vs-button :disabled="invalid" button="submit" class="ml-auto mt-2">
            {{
              $t('gallery.button.post')
            }}
          </vs-button>
        </div>
      </vx-card>
    </form>
  </ValidationObserver>
</template>

<script>
import moduleTagsMixin from '@/store/tag/moduleTagMixin'
import moduleMediaObjectMixin from '@/store/media-object/moduleMediaObjectMixin'
import moduleMediaMixin from '@/store/media/moduleMediaMixin'
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import RoleCheckbox from '../gallery-list/RoleCheckbox'
import Multiselect from 'vue-multiselect'

export default {
  mixins: [
    moduleTagsMixin,
    moduleMediaObjectMixin,
    moduleMediaMixin,
    moduleFolderMixin
  ],
  components: {
    Multiselect,
    RoleCheckbox
  },
  props: {
    folderId: {
      type: String,
      default: ''
    }
  },
  computed: {
    tags () {
      return this.$store.state.tag.tags
    },
    fileId () {
      return this.$store.state.mediaObject.fileId
    },
    roles () {
      return this.$store.state.AppActiveUser.roles
    }
  },
  data () {
    return {
      tagInput: [],
      roleInput: [],
      uploadError: '',
      tagError: '',
      uploadDone: false
    }
  },
  methods: {
    changeRoleInput (roleArray) {
      this.roleInput = roleArray
    },
    validateMedia (e) {
      this.uploadError = ''
      const file = e.filesx
      const formatSizeToMb = size => this.$options.filters.formatBytes(size)
      const checkSize = file
        .filter(file => !file.remove)
        .some(media => formatSizeToMb(media.size) >= 256)

      if (file.length === 0) {
        this.uploadError = this.$t('gallery.upload.form.error.no-file')
        return false
      }

      if (checkSize) {
        this.uploadError = this.$t('gallery.upload.form.error.file-size')
        return false
      }

      return true
    },
    async addAllMedias (e) {
      const isValid = this.validateMedia(e)
      if (!isValid) {
        return
      }

      const files = e.filesx.filter(file => !file.remove)

      this.$store.commit('media/SET_ALL_MEDIAS_SELECTED', false)
      this.$store.commit('folder/SET_LOADING', true)
      this.uploadError = ''
      this.uploadDone = false
      this.$vs.loading({
        container: '#form-loading',
        scale: 0.6,
        text: this.$i18n.t('gallery.loading.uploading', { total: files.length })
      })


      for (const file of files) {
        let mediaResponse = {}
        try {
          const mediaObjectId = this.$store.dispatch('mediaObject/addMediaObjectGallery', file)
          mediaResponse = await this.$store.dispatch('media/addMedia', {
            file: await mediaObjectId,
            folder: `/gallery/folders/${this.folderId}`,
            roles: this.roleInput,
            tags: this.tagInput.map(tag => tag['@id'])
          })

          if (mediaResponse && mediaResponse.status !== 201) {
            throw new Error(mediaResponse)
          }
        } catch (error) {
          if (mediaResponse.status === 422) {
            const fileName = mediaResponse.data['hydra:description'].split('filename :')[1].slice(0, -1)
            this.$vs.notify({
              title: this.$i18n.t('gallery.media.form.submit.error.notify.title', { media: fileName }),
              text: this.$i18n.t('gallery.media.form.submit.error.duplicate.notify.text'),
              color: 'danger'
            })

            await this.$store.dispatch('mediaObject/removeMediaObject', mediaResponse.data.violations[0].code)
          } else {
            this.$vs.notify({
              title: this.$i18n.t('gallery.media.form.submit.error.notify.status.title', { status: mediaResponse.status }),
              text: mediaResponse.data['hydra:description'],
              color: 'danger'
            })
          }
        }
      }
      this.uploadDone = true
      this.$emit('on-media-added', this.folderId)
      this.$vs.loading.close('#form-loading > .con-vs-loading')
      this.$store.commit('folder/SET_LOADING', false)
      this.resetForm()
    },
    async addTag (newTag) {
      if (newTag.length >= 30) {
        this.$refs.multiselect.isOpen = false
        this.tagError = this.$t('gallery.upload.form.input.tags.max_value')
        return
      }
      this.tagError = ''
      const { data } = await this.$store.dispatch('tag/addTag', {
        name: newTag
      })
      this.tagInput.push(data)
    },
    fetchTags () {
      this.$store.dispatch('tag/fetchTags')
    },
    resetForm () {
      this.tagInput = []
      this.$refs.fileInput.filesx = []
      this.$refs.fileInput.srcs = []
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.vs-con-loading__container {
  overflow: visible;
}
.title-form {
  font-size: 1.3rem;
  font-weight: 500;
  color: #444343;
}

.svg-icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.role {
  list-style: none;
}

.checkbox-role {
  z-index: 1;
}
</style>
