<template>
  <vs-button
    v-if="$acl.check('galleryUpload')"
    @click.prevent="onNewFolder"
    class="new-folder w-full mb-3 md:my-3 cursor-pointer"
    color="primary"
    type="relief"
    icon-pack="feather"
    icon="icon-folder"
    :disabled="disableNewFolder"
  >
    <div class="icon-add-folder">{{ $t("gallery.folder.new.title") }}</div>
    <ValidationObserver v-slot="{ invalid }">
      <vs-prompt
        :title="$t('gallery.folder.new.title')"
        :accept-text="$t('gallery.folder.form.submit')"
        :active.sync="activePromptFolder"
        :is-valid="!invalid"
        @accept="acceptCreateFolder"
      >
        <ValidationProvider
          :name="$t('gallery.folder.form.input.name')"
          class="w-full"
          rules="required|max:30"
          v-slot="{ errors }"
        >
          <vs-input
            type="text"
            :label="$t('gallery.folder.form.input.name')"
            :placeholder="$t('gallery.folder.form.input.name.placeholder')"
            v-model="folder.name"
            class="mt-5 w-full"
          />
          <span v-if="errors.length > 0" class="error-class">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <ValidationProvider
          :name="$t('gallery.folder.form.input.description')"
          class="w-full"
          v-slot="{ errors }"
        >
          <vs-input
            :label="$t('gallery.folder.form.input.description')"
            :placeholder="
              $t('gallery.folder.form.input.description.placeholder')
            "
            v-model="folder.description"
            class="mt-5 w-full"
          />
          <span v-if="errors.length > 0" class="error-class">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <ValidationProvider
          :name="$t('gallery.folder.form.input.date')"
          class="w-full block mt-5"
          rules="required"
          v-slot="{ errors }"
        >
          <small class="label-form">{{
            $t("gallery.folder.form.input.date")
          }}</small>
          <datepicker
            :placeholder="$t('gallery.folder.form.input.date.placeholder')"
            class="w-full"
            :language="langFr"
            :fullMonthName="true"
            :monday-first="true"
            minimumView="day"
            initialView="day"
            format="dd MMMM yyyy"
            name="date"
            v-model="folder.date"
          />
          <span v-if="errors.length > 0" class="error-class">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <div
          class="w-full block mt-5"
        >
          <small class="label-form">
            {{ $t("gallery.upload.form.input.tags") }}
          </small>
          <multiselect
            ref="multiselect"
            class="multiselect"
            v-model="folder.tags"
            :multiple="true"
            :options="tags"
            :showLabels="false"
            :taggable="true"
            @tag="addTag"
            trackBy="@id"
            label="name"
            :placeholder="$t('gallery.upload.form.multiselect.tags.choose')"
            :tag-placeholder="$t('gallery.upload.form.multiselect.tags.add')"
            :close-on-select="false"
            :limit="5"
          />
          <span class="error-class">{{ tagError }}</span>
        </div>
      </vs-prompt>
    </ValidationObserver>
  </vs-button>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import Multiselect from 'vue-multiselect'
export default {
  mixins: [moduleFolderMixin],
  components: {
    Datepicker,
    Multiselect
  },
  computed: {
    tags () {
      return this.$store.state.tag.tags
    },
    disableNewFolder () {
      return this.$store.state.folder.loading
    }
  },
  props: {
    folderId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      folder: {
        name: '',
        date: '',
        tags: [],
        parentFolder: null
      },
      roles: ['intern', 'marketing', 'press', 'public'],
      activePromptFolder: false,
      langFr: fr,
      tagError: ''
    }
  },
  methods: {
    async acceptCreateFolder () {
      let response = {}
      this.folder.tags = this.folder.tags.map(tag => {
        return tag['@id']
      })
      try {
        if (this.$route.params.folderId) {
          this.folder.parentFolder = `/gallery/folders/${this.folderId}`
          response = await this.$store.dispatch(
            'folder/addFolder',
            this.folder
          )
          this.folder.parentFolder = ''
        } else {
          response = await this.$store.dispatch(
            'folder/addFolder',
            this.folder
          )
          this.$router.push({
            name: 'folder',
            params: { folderId: response.data.id }
          })
        }
        this.resetForm()
        this.$emit('on-folder-added')
        this.$vs.notify({
          color: 'success',
          title: this.$t('gallery.folder.form.submit.success.notify.title'),
          text: this.$t('gallery.folder.form.submit.success.notify.text', {
            name: response.data.name
          })
        })
      } catch (e) {
        console.error(e) // eslint-disable-line no-console
        this.$vs.notify({
          color: 'danger',
          title: this.$t('gallery.folder.form.submit.error.notify.title'),
          text: this.$t('gallery.folder.form.submit.error.notify.text', {
            error: e.response.data['hydra:description']
          }),
          fixed: true
        })
      }
    },
    onNewFolder () {
      this.$emit('uncheckFolders')
      this.$store.commit('folder/SET_ALL_FOLDERS_SELECTED', false)
      this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
      this.activePromptFolder = true
    },
    async addTag (newTag) {
      if (newTag.length >= 30) {
        this.$refs.multiselect.isOpen = false
        this.tagError = this.$t('gallery.upload.form.input.tags.max_value')
        return
      }
      this.tagError = ''
      const { data } = await this.$store.dispatch('tag/addTag', {
        name: newTag
      })
      this.folder.tags.push(data)
    },
    resetForm () {
      this.folder = {
        name: '',
        date: '',
        tags: [],
        parentFolder: null
      }
    }
  },
  created () {}
}
</script>

<style scoped lang="scss">
.new-folder {
  z-index: 0;
}
.multiselect {
  z-index: 1;
}
.folders {
  transition: all 0.3s ease;
  &:hover {
    @apply shadow-md;
  }

  .icon-add-folder {
    font-size: 3rem;
    font-weight: 300;
    color: rgba(247, 148, 62, 0.7);
  }
}
.label-form {
  font-size: 0.85rem;
  padding-left: 5px;
}
</style>
