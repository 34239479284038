<template>
  <div class="flex items-center">
    <vs-icon class="block mr-3" color="primary" icon-pack="feather" icon="icon-calendar" size="20px" />
    <datepicker
      ref="myDatepicker"
      :placeholder="$t('gallery.filter.datepicker.search.placeholder')"
      @input="filterDate"
      :language="langFr"
      v-model="filterValue.date"
      :fullMonthName="true"
      :monday-first="true"
      minimumView="month"
      initialView="month"
      format="MMMM yyyy"
      name="date"
      input-class="datepicker-input"
      wrapper-class="datepicker-wrapper"
    />
    <vs-icon v-if="filterValue.date" @click="resetDate" class="block cursor-pointer ml-1" icon-pack="feather" icon="icon-x" size="15px" />
  </div>
</template>

<script>
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'
import { DateTime } from 'luxon'
export default {
  mixins: [moduleFolderMixin],
  components: { Datepicker },
  data () {
    return {
      filterItem: {},
      filterValue: {},
      langFr: fr
    }
  },
  methods: {
    hydratePayload () {
      const payload = {
        ...this.filterItem
      }
      this.$emit('on-filter-date', payload)
    },
    filterDate (date) {
      date = DateTime.fromJSDate(date).setZone('UTC').set({ hour: 0, minute: 0, second: 0 })
      if (!date.invalid) {
        this.filterItem = {
          date: {
            'strictly_after': date.minus({ days: 1}).toISODate(),
            'strictly_before': date.plus({ month: 1}).toISODate()
          }
        }
      } else {
        this.filterItem = {}
      }

    },
    resetDate () {
      this.$refs.myDatepicker.clearDate()
      this.$delete(this.filterItem)
      this.$delete(this.filterValue)
    }
  },
  watch: {
    filterItem () {
      this.hydratePayload()
    }
  }
}
</script>

<style lang="scss">
.datepicker-input {
    padding: 6px !important;
    height: 32px !important;
    border: 1px solid #e8e8e8 !important;
    &::placeholder {
      color: #bdbdbd !important;
      font-size: 0.9rem;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      @media screen and (max-width: 767px) {
        font-size: 0.85rem;
      }
    }
}
.datepicker-wrapper {
    width: 145px !important;
    height: 32px !important;
}
@media screen and (max-width: 992px) {
  .datepicker-wrapper {
    width: 100% !important;
    height: 32px !important;
  }
}
</style>
