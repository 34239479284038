<template>
  <div class="" id="gallery-card" v-if="foldersSelected.length > 0">
    <div class="mt-2" slot="header">
      <div class="flex items-center mb-2">
        <h1 class="text-base">
          {{ foldersSelected.length }}
          <span>{{
            foldersSelected.length > 1
              ? $t("gallery.folder.sidebar.selected.folders")
              : $t("gallery.folder.sidebar.selected.folder")
          }}</span>
        </h1>
        <vs-button v-if="$acl.check('galleryUpload')"
          @click="openDialogRemoveFolders"
          size="small"
          color="danger"
          icon="delete"
          class="ml-auto"
        />
      </div>
      <div
        v-for="folder in foldersSelected"
        :key="folder.id"
        class="flex flex-col"
        :class="isManyFoldersSelected ? 'mb-3' : ''"
      >
        <div class="flex mb-2">
          <feather-icon icon="FolderIcon" svgClasses="h-4 w-4" class="pr-2" />
          <h4 class="text-base">
            {{ folder.name }}
          </h4>
        </div>
        <div class="flex flex-wrap">
          <div v-for="folderTags in folder.tags" :key="folderTags.id" class="w-full my-1 mr-1">
            <vs-chip color="primary">
              {{ `#${folderTags.name}` }}
            </vs-chip>
          </div>
          <div v-if="isManyFoldersSelected">
            <div v-for="folderRole in folder.roles" :key="folderRole.id" class="block my-1 mr-1">
              <vs-chip class="mb-1" color="dark" v-if="folderRole">{{ $t(`gallery.roles.checkbox.${folderRole}`) }}</vs-chip>
              <vs-chip color="dark" v-else>{{ $t('gallery.roles.checkbox.admin') }}</vs-chip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ValidationObserver
      v-if="isOneFolderSelected"
      v-slot="{ invalid, handleSubmit }"
    >
      <form
        class="flex flex-col space-y-3"
        @submit.prevent="handleSubmit(updateFolder)"
      >
        <ValidationProvider
          :name="$t('gallery.folder.form.input.name')"
          class="w-full"
          rules="max:30"
          :is-valid="!invalid"
          v-slot="{ errors }"
        >
          <vs-input
            type="text"
            :label="$t('gallery.folder.form.input.name')"
            :placeholder="folderClone.name"
            :disabled="!userHasRules"
            v-model="folderClone.name"
            class="w-full"
          />
          <span v-if="errors.length > 0" class="error-class">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <ValidationProvider
          :name="$t('gallery.folder.form.input.description')"
          class="w-full"
          rules="max:100"
          v-slot="{ errors }"
        >
          <small class="label-form">{{
            $t("gallery.folder.form.input.description")
          }}</small>
          <vs-textarea
            type="text"
            :placeholder="folderClone.description ? folderClone.description : $t('gallery.media.form.input.description.many_selected.no_rules')"
            :disabled="!userHasRules"
            v-model="folderClone.description"
          />
          <span v-if="errors.length > 0" class="error-class">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <ValidationProvider
          :name="$t('gallery.folder.form.input.date')"
          class="w-full block"
          v-slot="{ errors }"
        >
          <small class="label-form">{{
            $t("gallery.folder.form.input.date")
          }}</small>
          <datepicker
            class="w-full sidebar-datepicker fullscreen-when-on-mobile"
            :language="langFr"
            :monday-first="true"
            :fullMonthName="true"
            minimumView="day"
            initialView="day"
            format="dd MMMM yyyy"
            :disabled="!userHasRules"
            name="date"
            v-model="folderClone.date"
          />
          <span v-if="errors.length > 0" class="error-class">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <div>
          <small class="label-form">{{
            $t("gallery.sidebar.form.input.tags")
          }}</small>
          <multiselect
            ref="multiselect"
            v-model="folderClone.tags"
            class="multiselect-custom"
            :multiple="true"
            :options="tags"
            :showLabels="false"
            :taggable="true"
            :disabled="!userHasRules"
            @tag="addTag"
            trackBy="@id"
            label="name"
            :placeholder="$t('gallery.upload.form.multiselect.tags.choose')"
            :tag-placeholder="$t('gallery.upload.form.multiselect.tags.add')"
            :close-on-select="false"
          />
          <span class="error-class">{{ tagError }}</span>
        </div>
        <ValidationProvider
          v-if="$acl.check('galleryUpload')"
          :name="$t('gallery.folder.sidebar.input.roles')"
        >
          <small class="label-form">{{ $t("gallery.folder.sidebar.input.roles") }}</small>
          <ul class="flex flex-wrap mb-2">
            <li
              v-for="(role, index) in folderClone.roles"
              :key="role[index]"
              class="my-1 mx-1"
            >
              <vs-chip color="dark" v-if="!role">{{ $t(`gallery.roles.checkbox.admin`) }}</vs-chip>
              <vs-chip color="dark" v-else>{{ $t(`gallery.roles.checkbox.${role}`) }}</vs-chip>
            </li>
          </ul>
        </ValidationProvider>
        <div v-if="$acl.check('galleryUpload')" class="flex flex-col space-y-2 mt-4">
          <vs-button button="submit" :disabled="invalid">
            {{ $t('gallery.media.form.submit.button') }}
          </vs-button>
          <vs-button @click="resetForm()" :disabled="invalid">
            {{ $t('gallery.media.form.cancel.button') }}
          </vs-button>
        </div>
      </form>
    </ValidationObserver>
    <vs-divider />
  </div>
</template>

<script>
import moduleTagsMixin from '@/store/tag/moduleTagMixin'
import moduleFolderMixin from '@/store/folder/moduleFolderMixin'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'
const cloneDeep = require('lodash.clonedeep')
export default {
  mixins: [moduleTagsMixin, moduleFolderMixin],
  components: {
    Multiselect,
    Datepicker
  },
  computed: {
    folderSelected () {
      return this.$store.getters['folder/getFirstFolderSelected']
    },
    foldersSelected () {
      return this.$store.state.folder.selectedFolders
    },
    tags () {
      return this.$store.state.tag.tags
    },
    isOneFolderSelected () {
      return this.$store.state.folder.totalSelectedFolders === 1
    },
    isManyFoldersSelected () {
      return this.$store.state.folder.totalSelectedFolders > 1
    },
    roles () {
      return this.$store.state.AppActiveUser.roles
    },
    userHasRules () {
      if (this.roles.includes('ROLE_GALLERY_MARKETING') && this.checkSelectionRoles('marketing')) {
        return true
      }
      if (this.roles.includes('ROLE_ADMIN') || this.roles.includes('ROLE_GALLERY_ADMIN')) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      folderClone: {},
      langFr: fr,
      tagError: ''
    }
  },
  methods: {
    async updateFolder () {
      try {
        await this.$store.dispatch('folder/updateFolder', this.folderClone)
        this.$emit('uncheckFolders')
        this.$vs.notify({
          color: 'success',
          text: this.$t('gallery.folder.form.update.folder.success.notify.text', { name: this.folderClone.name })
        })
        this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: this.$t('gallery.folder.form.update.folder.error.notify.title'),
          text: this.$t('gallery.folder.form.update.folder.error.notify.text', { error: error.response.data['hydra:description'] }),
          fixed: true
        })
      }
    },
    resetForm () {
      this.folderClone = cloneDeep(this.folderSelected)
      this.$emit('cancelSidebar')
    },
    async addTag (newTag) {
      if (newTag.length >= 30) {
        this.$refs.multiselect.isOpen = false
        this.tagError = this.$t('gallery.upload.form.input.tags.max_value')
        return
      }
      this.tagError = ''
      const { data } = await this.$store.dispatch('tag/addTag', {
        name: newTag
      })
      this.folderClone.tags.push(data)
    },
    async openDialogRemoveFolders () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('gallery.folder.sidebar.delete.selected.title'),
        text: this.$t('gallery.folder.sidebar.delete.selected.text'),
        accept: this.removeFoldersSelected,
        acceptText: this.$t('gallery.tags.table.actions.delete.dialog.acceptText'),
        cancelText: this.$t('gallery.tags.table.actions.delete.dialog.cancelText')
      })
    },
    async removeFoldersSelected () {
      this.$store.commit('folder/SET_ALL_FOLDERS_SELECTED', false) 
      try {
        this.$vs.loading({
          container: '#gallery-card',
          scale: 0.6,
          text: this.$i18n.t('gallery.loading.fetch.folder')
        })
        await this.$store.dispatch('folder/removeFolder', this.foldersSelected)
        this.$vs.loading.close('#gallery-card > .con-vs-loading')
        this.$emit('uncheckFolders')
        this.$store.commit('folder/CLEAR_SELECTED_FOLDERS')
        this.$vs.notify({
          color: 'success',
          text: this.$t('gallery.folder.form.remove.folder.success.notify.text')
        })
      } catch (error) {
        this.$vs.loading.close('#gallery-card > .con-vs-loading')
        this.$vs.notify({
          color: 'danger',
          title: this.$t('gallery.folder.form.remove.folder.error.notify.title'),
          text: this.$t('gallery.folder.form.remove.folder.error.notify.text', { error: error.response.data['hydra:description'] }),
          fixed: true
        })
      }
    },
    checkSelectionRoles (role) {
      return this.foldersSelected.every(element => {
        return element.roles.includes(role)
      })
    }
  },
  watch: {
    folderSelected: {
      handler () {
        this.folderClone = cloneDeep(this.folderSelected)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.sidebar-datepicker {
  .vdp-datepicker__calendar {
    position: fixed;
    right: 1%;
  }
  &.fullscreen-when-on-mobile {
    @media (max-width: 768px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
