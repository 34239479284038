<template>
  <div v-if="$acl.check('galleryUpload')">
    <ValidationProvider
      v-if="availablesRolesArray.length > 1"
      :name="$t('gallery.folder.sidebar.input.roles')"
      v-slot="{ errors }"
    >
      <!-- Specific header for each parent -->
      <slot name="sidebar"> </slot>
      <slot name="upload"> </slot>

      <ul class="flex flex-col md:flex-row flex-wrap mb-2">
        <li
          v-for="(availableRole, index) in availablesRolesArray"
          :key="availableRole[index]"
          class="md:w-1/2"
        >
          <vs-checkbox
            v-if="media && media.id"
            v-model="media.roles"
            :vs-value="availableRole"
            :checked="media.roles.includes(availableRole)"
            :disabled="roleMarketing ? defaultRoles[index] : null"
            class="checkbox-role text-sm space-y-1"
            >{{ $t(`gallery.roles.checkbox.${availableRole}`) }}</vs-checkbox
          >
          <vs-checkbox
            v-else-if="isManyMediasSelected"
            v-model="roleInputMedias"
            @change="$emit('on-check-role-sidebar', roleInputMedias)"
            :vs-value="availableRole"
            :checked="defaultRoles[index]"
            :disabled="roleMarketing ? defaultRoles[index] : null"
            class="checkbox-role text-sm space-y-1"
            >{{ $t(`gallery.roles.checkbox.${availableRole}`) }}</vs-checkbox
          >
          <vs-checkbox
            v-else
            v-model="roleInput"
            @change="$emit('on-check-role', roleInput)"
            :vs-value="availableRole"
            :checked="roleInput['marketing']"
            :disabled="roleMarketing ? defaultRoles[index] : null"
            class="checkbox-role space-y-1"
            >{{ $t(`gallery.roles.checkbox.${availableRole}`) }}</vs-checkbox
          >
        </li>
      </ul>

      <span v-if="errors.length > 0" class="error-class">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: 'RoleCheckbox',
  props: {
    media: {
      type: Object,
      required: false
    },
    roles: {
      type: Array,
      required: true
    },
    isManyMediasSelected: {
      type: Boolean,
      required: false
    },
    upload: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    roleMarketing () {
      return this.roles && this.roles.includes('ROLE_GALLERY_MARKETING')
    }
  },
  data () {
    return {
      availablesRolesArray: [],
      roleInput: [],
      roleInputMedias: [],
      defaultRoles: []
    }
  },
  methods: {
    getAvailablesRoles () {
      if (
        this.roles.includes('ROLE_GALLERY_ADMIN') ||
        this.roles.includes('ROLE_GALLERY_MARKETING')
      ) {
        this.availablesRolesArray.push('marketing', 'intern', 'press', 'public')
        this.defaultRoles.push('marketing')
        this.roleInput.push('marketing')
        this.roleInputMedias.push('marketing')
      }
    }
  },
  watch: {
    upload: {
      handler (uploadDone) {
        if (uploadDone) {
          this.roleInput = ['marketing']
        }
      }
    }
  },
  mounted () {
    this.getAvailablesRoles()
    this.$emit('on-check-role', this.roleInput)
    this.$emit('on-check-role-sidebar', this.roleInputMedias)
  }
}
</script>
