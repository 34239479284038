<template>
  <div class="filter-search flex items-center">
    <vs-icon class="block mr-3" color="primary" icon-pack="feather" icon="icon-search" size="20px" />
    <vs-input
      type="text"
      v-model="searchQuery"
      @keyup.enter="search"
      :placeholder="$t('gallery.filter.search_by_name.placeholder')"
      class="w-full"
    />
    <vs-icon
      @click="search"
      class="block ml-3 cursor-pointer"
      color="primary"
      icon-pack="feather"
      icon="icon-arrow-right-circle"
      size="20px"
    />
    <span class="ml-4" @click="$emit('remove-search')">
      <vs-chip closable v-if="searchQueryActive">{{
        searchQueryProps
      }}</vs-chip>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    searchQueryProps: {
      type: String,
      default: ''
    },
    searchQueryActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchQuery: ''
    }
  },
  methods: {
    search () {
      this.$emit('on-filter-search', { name: this.searchQuery })
      this.searchQuery = ''
    }
  }
}
</script>

<style lang="scss">
.vs-input--input {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  max-height: 32px;
}
.vs-input--placeholder.normal {
  font-size: 0.85rem;
  color: #bdbdbd !important;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  padding: 8px 0px 0px 8px !important;
}
</style>
